<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Sekeleton from "../../../components/widgets/loadingtable";
import Download from "../../../components/widgets/download.vue";
import ImageView from "../../../components/widgets/imageView.vue";
import VideoView from "../../../components/widgets/videoView.vue";
import AudioView from "../../../components/widgets/audioView.vue";
import Helper from "../../../api/helper";
import belowNav from '../../../components/belowNavbar.vue'
export default {
  page: {
    title: "Recent Files",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout,Sekeleton,Download,ImageView,VideoView,AudioView,belowNav },
  data() {
    return {
      title: "Trash",
      sortName : false,
      counter : 0,
      item : {isFolder:false}
    }
  },
  computed: {
    listings: {
      // getter
      get: function () {
        var data = this.$store.getters[this.$store.getters['state/state']+'/recentList'];
        return data.filter(v=>!v.isFolder)
      },
      // setter
      set: function (newValue) {
        this.$store.commit(this.$store.getters['state/state']+"/SET_RECENTLIST",newValue);
      }
    },
    loading(){
        return this.$store.getters['storage/loading'];
    },
    grid(){
        return this.$store.getters['storage/gridView'];
    },
    state(){
        return this.$store.getters['state/state'];
    },
    canAccess(){
        var id = this.$store.getters['user/id'];
        var group = this.$store.getters['workgroup/workgroup'];
        var st = this.$store.getters['state/state'];
        if(st == "storage"){
          return true
        }else{
          if(id == group.owner_id){
            return true;
          }else{
            return group.access_type != "View";
          }
        }
    }
  },
  mounted(){
    // this.$ga.page('/recent')
    this.$ga.event('recent-page', 'actionx', 'labelx', 1)
    this.$store.commit("storage/SET_SHOW_PROPERTIES",false);
    this.$store.commit("share/SET_SHOW_PROPERTIES",false);
    this.$store.commit("workgroup/SET_SHOW_PARTICIPANTS",false);
    this.$store.commit("storage/SET_ALL_SELECTED",[]);
    const sideBarValue = this.$store.getters['storage/sideRightToggle'];
    this.$store.dispatch('storage/setSideRightToggle',{isShowing: sideBarValue, isPersistent: true});
    if(this.$store.getters["upload/files"].length > 0){
      document.getElementById("uploadLoading").style.display = "block";
    }else{
      document.getElementById("uploadLoading").style.display = "none";
    }
    this.$store.dispatch(this.state+"/recent");
    this.$store.commit(this.state+"/SET_SHOWBREADCUMB",false);
    this.$store.commit("storage/SET_SUBTITLE","Recent Files");
  },
  methods:{
    openParent(list){
      // list.parent_folder,list.parent_id == ''
      if(list == null){list = this.item}
      if(list.parent_id == ''){
          this.$store.commit(this.state+"/SET_PARENT_ID","");
          this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
          this.$router.push({ name: "home" }); 
      }else{
        this.$store.commit(this.state+"/SET_PARENT_ID",list.parent_folder._id);
        // this.$store.commit(this.state+"/SET_BREADCUMB",list.parent_folder);
        this.$store.dispatch(this.state+"/listing")
        this.$router.push({ name: "home" }); 
      }
      this.hideContextMenu();
    },
    openFile(list){
      if(list == null){list=this.item}
      if(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4){

        if(Helper.supportOnlyOffice(list.extension)){
            if(this.canAccess){
              localStorage.setItem("access_type", JSON.stringify("edit"))
            }else{
              localStorage.setItem("access_type", JSON.stringify("view"))
            }
            this.$store.dispatch("storage/open",list);
        }else if(Helper.isImages(list.extension)){
            this.$store.dispatch("storage/getLinkFile",list);
            this.$store.dispatch("storage/fileretrieve",{_id : list._id,directory:list.directory});
              let imageView = document.getElementById("imageView");
              imageView.style.display = 'block';
        }else if(Helper.isVideos(list.extension)){
            this.$store.dispatch("storage/fileretrieve",{_id : list._id,directory:list.directory})
            .then(c=>{
              let video = document.getElementById("VideoView");
              video.style.display = 'block';
              this.$store.dispatch("storage/getLinkFile",c)
              .then((v)=>{
                let videoPlayer = document.getElementById("videoPlayer");
                videoPlayer.src = v;
                videoPlayer.play();
              });
            });
        }else if(Helper.isAudios(list.extension)){
            this.$store.dispatch("storage/fileretrieve",{_id : list._id,directory:list.directory})
            .then(c=>{
              let audio = document.getElementById("AudioView");
              audio.style.display = 'block';
              this.$store.dispatch("storage/getLinkFile",c)
              .then((v)=>{
                let audioPlayer = document.getElementById("audioPlayer");
                audioPlayer.src = v;
                audioPlayer.play();
              });
            });
        }else{
          this.$store.dispatch("storage/download",
          {
              directory : list.directory,
              hash_name : list.hash_name,
              file_name : list.file_name
          }
          );
        }
        this.hideContextMenu();
      }
    },
    righClick(item){
      this.item = item;
      var menu = document.getElementById("contextMenu");
      var field = document.getElementById(item._id+"item");
      menu.style.display = 'block';
      menu.style.right = null;
      menu.style.left = field.offsetLeft+field.offsetWidth-60+"px";
      menu.style.bottom = null;
      menu.style.top = field.offsetTop +60+ "px";
    },
    hideContextMenu(){
      var menu = document.getElementById("contextMenu");
      menu.style.display = 'none';
      menu.style.top = 0;
    }
  }
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <!-- <div class="row"> -->
      <belowNav/>
      <Download/>
      <ImageView/>
      <VideoView/>
      <AudioView/>
      <div class="col-12 all-pages">
        <div class="page-title-box d-flex align-items-center justify-content-between" v-if="!grid">
          <!-- <pre>{{listings}}</pre> -->
            <table class="table table-storage default-text-style">
                <thead>
                    <th width="50%" class="default-text-style">
                        <span class="text-muted">Name</span> 
                        <!-- <i v-if="!sortName" class="bx bx-caret-up"></i> -->
                        <!-- <i v-else class="bx bx-caret-down"></i> -->
                    </th>
                    <th width="15%" class="default-text-style"><span class="text-muted">Location</span></th>
                    <th width="20%" class="default-text-style table-web-display"><span class="text-muted">Discarded Date</span></th>
                    <th width="15%" class="default-text-style table-web-display"><span class="text-muted">File Size</span></th>
                </thead>
                <Sekeleton v-if="loading"/>
                <tbody v-if="listings.length > 0 && !loading">
                    <tr v-for="(list,index) in listings" :key="index">
                        <td class="align-items-center"  @dblclick="(counter += 1), openFile(list)">
                            <div   class="d-flex align-items-center py-2">
                                <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4" :src="require(`@/assets/images/icon/lock-encrypt.svg`)" alt height="24px"/>
                                <img v-else :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                                <span class="file-name mx-2">{{list.file_name}}</span>
                            </div>
                        </td>
                        <td  class="">
                            <div class="file-name-recent">
                              <a href="javascript:void(0);" @click="openParent(list)"> {{list.parent_id == "" ? state == 'storage' ? "My Files" : "Group Files" : list.parent_folder.file_name}}</a>
                            </div>
                        </td>
                        <td  class="table-web-display">
                           <p> {{list.datemodified}}</p>
                        </td>
                        <td  class="table-web-display">
                            <p>{{list.size}}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="page-title-box d-flex justify-content-start align-items-start grid-view" id="container-file" v-else>
          <table class="table mt-4 table-storage default-text-style " sticky-header>
            <thead>
                <th width="64%" class="default-text-style" colspan="3"> 
                      <span class="text-muted">Name </span>
                </th>
                <th width="5%"></th>
            </thead>
          </table>
          <div class="d-flex justify-content-start align-items-start grid-view" v-if="loading">
            <div class="card dip-card-item m-2" v-for="loop in 18" :key="loop">
              <div class="card-body d-flex flex-column justify-content-center align-items-center">
                <div class="mt-4"></div>
                <b-skeleton-img card-img="left" width="72px" height="72px"></b-skeleton-img>
                <div class="mt-2"></div>
                <b-skeleton width="100px"></b-skeleton>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-start grid-view"  v-if="listings.length > 0 && !loading">
            <div  :class="`card dip-card-item m-2`" 
                v-on:click.ctrl="ctrlClick(list)" v-for="(list,index) in listings" 
                :key="index" 
                :id="list._id+'item'" 
                style="height: 164px; width: 168px"
                @contextmenu.prevent="righClick(list)"
                v-on:click.exact="hideContextMenu"  
                @dblclick="(counter += 1), openFile(list)"
                v-click-outside="hideContextMenu"
                >
              <div class="card-body">
                <div class="mt-4"></div>
                <div :id="index+'card'" class="d-flex flex-column justify-content-center align-items-center">
                  <div class="mb-1">
                    <img 
                      v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4"
                      class="avatar-md"
                      :src="require(`@/assets/images/icon/lock-encrypt.svg`)"
                      alt/>
                    <img
                      v-else
                      class="avatar-md"
                      :src="require(`@/assets/images/icon/${list.icon}`)"
                      alt
                    />
                  </div>
                  <h5 class="default-text-style elipse-text">
                    <a
                      href="javascript: void(0);"
                      class="text-dark "
                      >{{ list.file_name }}</a
                    >
                  </h5>
                </div>
                <b-tooltip :target="index+'card'" placement="top" :delay="{show:800,hide:100}">
                  {{ list.file_name }}
                </b-tooltip>
              </div>
            </div> 
          </div>
          <b-list-group id="contextMenu">
            <b-list-group-item button @click="openFile(null)">
              <img src="@/assets/images/icon/Move.png" alt height="100%" />
              <span class="ml-3 default-text-style">Open</span>
            </b-list-group-item>
            <b-list-group-item button @click="openParent(null)">
              <img src="@/assets/images/icon/Information.png" alt height="100%" />
              <span class="ml-3  default-text-style">Open File Location</span>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="listings.length <= 0 && !loading"  class="d-flex flex-column justify-content-center align-items-center">
            <span class="mt-2"></span><br>
            <img src="@/assets/images/empty-recent.svg" alt class="img-fit mb-4" />
            <p class="mb-0 pb-0 title-empty mb-2">No recent files</p>
            <p class="text-empty pt-0 mt-0">Quickly find and access recently opened files here.</p>
        </div>
      </div>
    <!-- </div> -->
    <!-- end page title -->
  </Layout>
</template>
